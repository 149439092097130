/* side navigation */
.side-nav {
    /*width: 200px;*/
    /*max-width: 200px;*/
    min-width: 150px;
}

    .side-nav a {
        color: #4e5959;
        width: 100%;
        font-weight: bold;
    }

        .side-nav a:hover {
            background-color: #42c0c0;
        }

.activeTab {
    background-color: #42c0c0;
}