/* footer specific css */
/*.review-comment {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    white-space: pre-wrap;
    padding-left: 16px;
    padding-right: 16px;
}*/

.review-comment {
    margin-left: 25px;
    margin-right: 20px;
}

.review-name {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
}


