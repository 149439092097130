.map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #42c0c0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -35px 0 0 -15px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.booking-float {
    background-color: white;
    color: #4e5959;
    min-width: 365px;
    max-width: 100%
}

.booking-static {
    /*background-color: #4e5959;*/
    /*color: white !important;*/
    background-color: white;
    position: sticky;
    top: 90px;
    width: 365px;
    overflow-y: scroll;
    max-height: calc(100vh - 160px);
    min-height: 420px;
}
    /* Hide scrollbar for Chrome, Safari and Opera */
    .booking-static::-webkit-scrollbar {
        display: none;
    }

.night-price {
    padding-top: 10px;
    background-color: #4f5962;
    color: white;
    font-size: xx-large;
    margin-left: 0px;
    margin-right: 0px;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

/*.payment-list-price-detail {
    width: 100%;
    background-color: blue;
}*/

.payment-list-price-detail li {
    width: 100%;
}

.payment-list-price-detail span {
    float: right;
}

.payment-due {
    font-weight: 700;
}

.btn-block {
    width: 100%;
}

#image {
    max-width: 100%;
    max-height: 500px;
}

.book-button {
    width: 100%;
    position: fixed;
    /*height: 50px;*/
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 50px;
    background-color: white;
    z-index: 99;
}

.Image-app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80vh;
    background-color: white;
}

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 120px;
}

.controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.viewer {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
}

.heroimage {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
}

.herooutput {
    position: relative;
    width: 100vw;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

    .herooutput img {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: top left;
    }

.output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

    .output img {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: top left;
    }

input.checkbox {
    width: 20px;
    height: 20px;
}

@media only screen and (min-width: 993px) {
    .listingImageSlider {
        height: 500px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 992px) {
    .listingImageSlider {
        height: 350px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 768px) {
    .listingImageSlider {
        height: 200px;
        object-fit: cover;
    }
}

.mediaPrimary {
    background-color: lightgray;
    padding: 10px;
}

.mediaStd {
    padding: 10px;
}

.listing-favourite {
    position: relative;
    right: 180px;
    top: -80px;
    font-size: 40px;
    z-index: 99;
}

