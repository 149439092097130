.star-rating {
    margin: 16px;
}
.staron {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #e96852;
}

.staroff {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #ccc;
}
