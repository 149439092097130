/* footer specific css */
.trending-title {
    position: absolute;
    width: 100%;
    color: white;
    text-align: center;
    bottom: 20px;
    font-size: 20px;
    z-index:2;
}

.trending-item {
    position: relative;
    width: 100%;
    /*border-color: coral;*/
    border-width: 4px;
    /*border-style: solid;*/
    border-radius: 25px;
    /*padding: 5px;*/
    overflow: hidden;
    
}

/*.trending-item:hover {
    border-color: coral;
    border-width: 4px;
    border-style: solid;
    border-radius: 25px;
    padding: 5px;
}*/

.trending-img {
    width: 100%;
    /*border-radius: 15px;
    padding: 5px;*/
}

.trending-img:hover {
    transform: scale(1.05);
}

h1 {
    text-align: center;
    width: 100%;
}
