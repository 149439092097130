/* footer specific css */
a {
    text-decoration: none;
}

.search-title {
    width: 100%;
    color: #4e5959;
    font-size: 20px;
    text-decoration:none;
    padding: 10px;
    
}

.search-details {
    width: 100%;
    color: #4e5959;
    text-decoration: none;
    padding: 5px;
    font-size: small;
    text-align: center;
}

.detail-padding {
    padding: 3px;
}

.search-item {
    position: relative;
    width: 100%;
    /*border-color: coral;
    border-width: 4px;
    border-style: solid;*/
    border-radius: 10px;
    /*padding: 5px;*/
    overflow: hidden;
    background-color: white;
    margin-bottom: 10px;
}

.search-featured {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 25px;
    color: #fdbd3c;
    z-index: 99;
}

.search-favourite {
    position: absolute;
    right: 20px;
    top: 200px;
    font-size: 25px;
    color: #fdbd3c;
    z-index: 99;
}

.search-price {
    position: absolute;
    left: 20px;
    top: 200px;
    font-size: 25px;
    color: white;
    z-index: 99;
    outline-color: black;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.sm-input {
    height: 65px;
}

/*.trending-item:hover {
    border-color: coral;
    border-width: 4px;
    border-style: solid;
    border-radius: 25px;
    padding: 5px;
}*/

.search-img {
    width: 100%;
    /*border-radius: 15px;
    padding: 5px;*/
}

.search-img:hover {
    transform: scale(1.05);
}

.guest-input {
    height: 45px;
    border-radius: 4px;
    width: 100%;
}

.guest-search {
    font-size: 16px;
    border-radius: 4px;
    padding: 20px;
    z-index: 50;
    background-color: #fff;
    position: absolute;
    min-width: 220px;
    /*margin-left: 30px;*/
}

.guest-row {
    text-align: left !important;
    width: 100%;
    padding-bottom: 10px;
}

.guest-label {
    line-height: 44px;
    text-align: left;
    font-weight: 400;
    height: 42px;
}


.guest-adult {
    line-height: 44px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
}

.guest-child {
    line-height: 44px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
}

.guest-adult-minus {
    color: #42c0c0;
    background-color: #fff;
    border-style: solid;
    border-width: 10px;
    border-top: 2px solid #42c0c0;
    border-top-left-radius: 20px;
    border-bottom: 2px solid #42c0c0;
    border-bottom-left-radius: 20px;
    border-left: 2px solid #42c0c0;
    text-align: center;
    right: 69px;
    position: absolute;
    height: 42px;
    width: 49px;
    border-right: none;
}

.guest-adult-plus {
    color: #42c0c0;
    background-color: #fff;
    border-style: solid;
    border-width: 10px;
    border-top: 2px solid #42c0c0;
    border-top-right-radius: 20px;
    border-bottom: 2px solid #42c0c0;
    border-bottom-right-radius: 20px;
    border-right: 2px solid #42c0c0;
    text-align: center;
    right: 20px;
    position: absolute;
    height: 42px;
    width: 49px;
    border-left: none;
}

.guest-adult-plus:hover {
    color: #fff;
    background-color: #42c0c0;
}

.guest-adult-minus:hover {
    color: #fff;
    background-color: #42c0c0;
}

.petSelected {
    color: #fff;
    background-color: #42c0c0;
}

.guest-child-minus {
    color: #42c0c0;
    background-color: #fff;
    border-style: solid;
    border-width: 10px;
    border-top: 2px solid #42c0c0;
    border-top-left-radius: 20px;
    border-bottom: 2px solid #42c0c0;
    border-bottom-left-radius: 20px;
    border-left: 2px solid #42c0c0;
    text-align: center;
    right: 69px;
    position: absolute;
    height: 42px;
    width: 49px;
    border-right: none;
}

.guest-child-plus {
    color: #42c0c0;
    background-color: #fff;
    border-style: solid;
    border-width: 10px;
    border-top: 2px solid #42c0c0;
    border-top-right-radius: 20px;
    border-bottom: 2px solid #42c0c0;
    border-bottom-right-radius: 20px;
    border-right: 2px solid #42c0c0;
    text-align: center;
    right: 20px;
    position: absolute;
    height: 42px;
    width: 49px;
    border-left: none;
}

    .guest-child-plus:hover {
        color: #fff;
        background-color: #42c0c0;
    }

.guest-child-minus:hover {
    color: #fff;
    background-color: #42c0c0;
}

.guest-button {
    text-align: right;
}

.testing {
    pointer-events: none;
}
