.blog-map {
    height: 400px !important;
    position: relative !important;
}

.mbsc-segmented {
    padding: 0px !important;
}

.mbsc-segmented-button {
    height: 36px !important;
}