.invoice-header {
    display: block;
}

.block-left {
    display: block;
    float: left;
}

.block-right {
    float: right;
}