
@font-face {
    font-family: 'Fredoka One';
    src: local('Fredoka One'), url(./../../fonts/FredokaOne-Regular.ttf) format('truetype');
}

/* site wide css */
html, body {
    /*height: 100%;*/
    margin-bottom: 50px;
    color: #4e5959;
    /*font-family: FredokaOne !important;*/
    /*font-family: Fredoka One, Arial !important;*/
    font-family: Arial !important;
}

.mbsc-button-primary {
    background-color: #42c0c0 !important;
}

.btn-primary {
    background-color: #42c0c0 !important;
    color: white !important;
}

.btn-secondary {
    background-color: #fdbd3c !important;
    color: #4e5959 !important;
}

a {
    color: #fdbd3c;
}

h1 {
    text-align: center !important;
    width: 100%;
    color: #42c0c0 !important;
}

h2 {
    color: #42c0c0 !important;
}

h4 {
    color: #42c0c0;
}

h5 {
    padding-top: 10px;
}

.search-div {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 200px;
    z-index: 2;
}

.input-error {
    border-color: red;
    background-color: orange;
}

.search-mobile {
    background: white !important;
    width: 95%;
}


@media only screen and (max-width: 992px) {
    /* For mobile phones: */
    [class*="search-div"] {
        top: 130px;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    /*[class*="search-div"] {
        top: 100px;
    }*/

    .DateRangePicker {
        width: 100%;
    }

    .DateRangePickerInput {
        width: 100%;
    }

    .DateInput {
        width: 42% !important;
    }

    [class*="DateRangePicker_picker"] {
        z-index: 3 !important;
    }
}


.content {
    height: 100%;
}

@media only screen and (max-width: 992px) {
    .nav-tabs {
        /*display: none;*/
        font-size: 12px;
    }
}
.nav-item {
    width: 12.5%;
    text-align: center;
}

.nav-link {
    width: 100%;
}

.tab-content {
    /*height: 100%;*/
}

.row {
    /*padding-top: 10px;*/
    padding-bottom: 10px;
    width: 100%;
    margin-left: 0px;
}

#map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
}

.map {
    /*overflow: hidden;*/
    /*padding-bottom: 10.25%;*/
    display: flex;
    flex-direction: column;
    position: fixed;
    /*height: calc(100vh - 238px);*/
    height: calc(100vh - 150px);
    width: 48vw;
    /*padding-right: 50px;*/
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid white;
    border-bottom: 16px solid white;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.label {
    border-radius: 2.5px;
    color: white;
    padding-bottom: 3px;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
}

.label-success {
    background-color: #cadb2d;
}

.label-primary {
    background-color: #42c0c0;
}

.label-secondary {
    background-color: #fdbd3c;
}

.label-danger {
    background-color: #e96852;
}

.label-info {
    background-color: #808080;
}

.action-button {
    margin-bottom: 10px;
}

.loading-overlay {
    background-color: gray;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 99;
}

.loading-vertical {
    margin: 0;
    position: absolute;
    left: calc(50% - 50px);
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.loading-listing-message {
    background-color: gray;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 350px;
    z-index: 99;
    margin-left: -29px;
}

.mbsc-remove-margins {
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
}

.blog-intro {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: medium;
    width: calc(100% - 1px);
    color: #4e5959;
    text-decoration: none;
    padding-left: 10px;
}

    .blog-intro h2 {
        font-size: x-large;
    }

    /*.slick-prev {
    color: red !important;
    background-color: blue !important;
}

.slick-next {
    color: red !important;
    background-color: blue !important;
}*/

    .slick-prev:before,
    .slick-next:before {
        font-size: 40px !important;
        color: gray !important;
    }

    .slick-prev {
        left: -45px !important;
    }

    .slick-dots {
        height: 50px;
    }

    .slick-slide {
        padding-left: 5px;
        padding-right: 5px;
    }

    .container {
        padding-top: 20px;
    }

    .home-col {
        padding-top: 20px;
    }

    .iframe {
        /*height: 320px;*/
        width: 100%;
        aspect-ratio: 16/9;
    }

    .pac-container {
        z-index: 9999 !important;
    }

.decline-reason {
    border-radius: 5px;
    padding: 5px;
    outline: solid 2px red;
}

.icon-button {
    padding: 0px;
}

.my-modal-success .modal-content {
    background-color: #fdbd3c;
    color: #4e5959;
}

.my-modal-fail .modal-content {
    background-color: #f3b1a5;
}

.my-modal-fail a {
    color: #99ff33;
}

/*hide thead recaptcha logo*/
.grecaptcha-badge {
    display: none !important;
}


.homesearch .mbsc-error-message {
    color: white !important;
    font-size: 14px !important;
    font-weight: 800;
}


.LinesEllipsis-ellipsis {
    color: #42c0c0;
    cursor: pointer;
}