.md-calendar-booking .mbsc-calendar-text .mbsc-material {
    text-align: center;
}

.listing .mbsc-calendar-labels {
    font-size: x-large;
    top: 40px;
}

.calAmountCenter {
    text-align: center !important;
}
.mbsc-calendar-text.mbsc-ltr {
    text-align: center !important;
}
/*.mbsc-calendar-labels {
    text-align: center !important;
}*/
.md-calendar-booking .booking-datetime .mbsc-datepicker-tab-calendar {
    flex: 1 1 0;
    min-width: 300px;
}

.md-calendar-booking .mbsc-timegrid-item {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.md-calendar-booking .mbsc-timegrid-container {
    top: 30px;
}

.mbsc-datepicker .mbsc-ltr.check-in {
    background: linear-gradient( to left top, #46c4f3 50%, transparent 50%) no-repeat;
}

.mbsc-datepicker .mbsc-ltr.check-out {
    background: linear-gradient( to left top, transparent 50%, #46c4f3 50%) no-repeat;
}

.mbsc-datepicker .mbsc-rtl.check-in {
    background: linear-gradient( to right bottom, #46c4f3 50%, transparent 50%) no-repeat;
}

.mbsc-datepicker .mbsc-rtl.check-out {
    background: linear-gradient( to right bottom, transparent 50%, #46c4f3 50%) no-repeat;
}

.mbsc-datepicker .mbsc-ltr.check-out,
.mbsc-datepicker .mbsc-rtl.check-in {
    border-color: #46c4f3;
}

.mbsc-datepicker .mbsc-windows.check-in,
.mbsc-datepicker .mbsc-windows.check-out {
    border-color: transparent;
}

.mbsc-calendar-cell:hover {
    /*background-color: grey;*/
}

.mbsc-button {
    height: 56px;
}

.listing .mbsc-calendar-cell {
    height: 70px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    font-size: xx-large;
}
