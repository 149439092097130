

#cleaningRateDaily, #cleaningRatePerStay {
    display: inline-block;
    position: relative;
    margin-right: 10px;  
    margin-left: auto;
    height: 24px;
    margin-bottom: 5px;
}

#label-entire-place, #label-private-room, #label-additional-guests, #label-cleaning-rate {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 2px;
    justify-content: left;
    padding-top: 7px;
    
}

#informationtitle.form-control,
#informationnumberofbedrooms.form-control,
#informationnumberofbeds.form-control,
#informationnumberofbathroosm.form-control,
#informationbedtype.form-control  {
    margin-left: 10px;
    width: 96.5%;
}


