/* footer specific css */
.comment {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.name {
    text-align: right;
}


