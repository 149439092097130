/* footer specific css */
.footer-wrap {
    background-color: white;
    color: #4e5959;
    /*position: relative;
    height: 50px;
    margin-top: -50px;*/
    position: fixed;
    height: 50px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    z-index: 99;
}

.social-icons {
    text-align: right;
}

.social-icons a {
    display: inline-block;
    width: 45px;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #4e5959;
    margin-top: -20px;
}