.dropdown-menu {
    width: 210px !important;
}

#nav-btn {
    background: transparent;
    border: none;
    padding-top: 5px;
    /*color: #4e5959;*/
}

#dropdown-button {
    background: transparent;
    border: none;
    /*color: #4e5959;*/
}

    #dropdown-button:active, #dropdown-button:focus, #dropdown-button:hover{
        outline: none;
        color: #4e5959;
    }

.dropdown-item .link {
    font-family: Verdana;
    color: #4e5959;
    font-weight: bold;
}

.nav-white {
    background-color: white;
    color: #4e5959;
}

.nav-home {
    /*background-color: #4e5959;*/
    color: white !important;
    
}
