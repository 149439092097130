/* footer specific css */
.message-comment {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-left: 53px;
    white-space: pre-wrap;
}

.message-name {
    text-align: left;
}


